<template>
    <div class="">
        <iframe id="blockrandom" name="iframe" src="/professionnalisation2" width="100%" height="12000" loading="lazy" title="Le programme de professionnalisation">
        Cette option ne fonctionnera pas correctement. Malheureusement, votre navigateur ne supporte pas les frames.    </iframe>
    </div>
</template>


<script>


export default {
    name: 'Professionnalisation3',
}
</script>


